//Style pour les icons icomoon

@mixin icomoon {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin absoluteCenter {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}
