//Style pour 07basexo.hmtl

//Corps de la page
.consigne .dragdropzone {
  border: em(6,48) solid $pilier_color;
  box-sizing: border-box;
  border-radius: em(20,48);
  width: em(110,48);
}
.type10 > .consigne {
  transform: none;
  top: 8%;
  left: 29.25%;
}

.reponses.dragdropzone {
  transform: none;
  top: calc(70% - 2.2916666667em/2);
  left: 8.5%;
  
}
.reponses .dropzone {
  position: relative;
  &.draggable-container--is-dragging {
    background: transparent;
  }
  
}
.dropreponse {
  min-width: em(98,48);
  min-height: em(98,48);
  text-align: center;
  .item {
    border-radius: em(14,48);
    line-height: em(98,48);
    width: em(98,48);
    height: em(98,48);
  }
}
.reponses .dropzone .item {
  &.draggable-source--is-dragging {
    color: transparent;
    background: transparent;
  }
}
.item {
  background: $grisclair;
  border-radius: em(20,48);
  color: $pilier_color;
  text-align: center;
  &:last-child {
    margin-right: 0;
  }
  &.draggable-mirror{
    background: mix($grisclair, rgba(255,255,255,0), 70%);
    min-width: em(98,48);
  }
}
