//Style pour 07basexo.hmtl

//Corps de la page

.loader-exercice {
  display: none;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: white;
  position: fixed;
  p {
    @include absoluteCenter;
    color: $grismoyen;
    font-size: em(72,16);
    width: 100%;
    text-align: center; 
    font-weight: bold;
  }
}

.show-loader {
  display: block;
  z-index: 2000;
}

.progress-top {
  z-index: 1000;
  border-radius: 10vw;
  overflow: hidden;
  position: absolute;
  top: 1.5em;
  left: 15%;
  width: 70%;
  height: em(20 ,16);
  background-image: linear-gradient(white 50%, transparent 50%);
  background-size: 1px 6px;
  background-position: 0 calc(50% + 1.5px);
  background-repeat: repeat-x;
  &::before, {
    z-index: 0;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: em(20,16);
    height: em(20,16);
    border-radius: 50%;
    background-color: $progress;
  }
  &::after, {
    z-index: -1;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: em(20,16);
    height: em(20,16);
    border-radius: 50%;
    background-color: white;
  }
  progress {
    z-index: 1;
    appearance: none;
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    &::-webkit-progress-bar {
      background: transparent;
    }
    &::-webkit-progress-value {
      background: $progress;
      transition: width .5s;
    }
    &::-moz-progress-bar {
      background: $progress;
    }
  }
}

.mainexo {
  width: 100%;
  height: 100%;
  background: $pilier_color;
  position: absolute;
  top: 0;
  left: 0;
  .rouge & {
    background: $errorcolor;
  }
}
.exosimple {
  margin: 2%;
  width: 96%;
  height: 78vh;
  background: white;
  border-radius: 3.15vw;
  position: absolute;
  left: 0;
  bottom: 0;
  .consigne {
    position: absolute;
    top: 26%;
    left: 50%;
    width: 41.5%;
    transform: translate(-50%,-50%);
    display: flex;
    font-weight: bold;
    font-size: em(48,16);
    color: $pilier_color;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    &>* {
      width: 50%;
    }
  }
  .reponses {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 83%;
    display: flex;
    font-weight: bold;
    font-size: em(48,16);
    color: $pilier_color;
    align-items: center;
    justify-content: space-around;
    .lettre {
      border-radius: 17%;
      border: em(5,48) solid transparent;
      box-sizing: border-box;
      width: em(110,48);
      height: em(110,48);
      text-align: center;
      &:last-child {
        margin-right: 0;
      }
    }
    p {
      line-height: em(100,48);
      background: $grisclair;
      border-radius: calc(17% - 0.1041666667em);
      text-align: center;
      &:last-child {
        margin-right: 0;
      }
      &.draggable-mirror{
        background: mix($grisclair, rgba(255,255,255,0), 70%);
      }
    }
  }
  .help {
    margin: em(10,45);
  }
}
.hearing {
  &::before {
    @include icomoon;
    content:"\e908";
    color: white;
    font-size: 1.5em;
    padding: 0.24em;
    background-color: $pilier_color;
    border-radius: 50%;
  }
}
