//Style pour 13hearingselectimg.hmtl
.consigne.type16a {
  transform: none;
  top: 8%;
  left: 8.5%;
  justify-content: start;
  width: 83%;
  margin-left: 0;
  margin-right: 0;
  .hearing {
    width: 25%;
    font-size: em(33,48);
  }
  .text {
    width: 25%;
  }
  .dragdropzone {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: em(210,48);
    border-radius: em(20,48);
    border: em(6,48) solid $pilier_color;
    box-sizing: border-box;
    .dropreponse {
      display: inline-block;
      border: none;
      background: transparent;
      position: relative;
      p {
        @include absoluteCenter;
      }
    }
  }
}
