$timing: 1s;

@keyframes right_show {
  0% { transform: translateX(100%); }
  100% { transform: translateX(0%); }
}

.right_show {
  &.topnav {
    z-index: 900;
  }
  &.progress-top {
    z-index: 900;
  }
  z-index: 800;
  animation: right_show $timing cubic-bezier(0.465, 0.183, 0.153, 0.946);
}

@keyframes right_hide {
  0% { transform: translateX(0%); }
  100% { transform: translateX(-50%); }
}

@keyframes hide_after {
  20% { opacity: 0; }
  100% { opacity: .5; }
}

.right_hide {
  &.topnav {
    z-index: 600;
  }
  &.progress-top {
    z-index: 600;
  }
  z-index: 400;
  animation: right_hide $timing cubic-bezier(0.465, 0.183, 0.153, 0.946);
  &:not(.progress-top):after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    background: $noir;
    opacity: 0;
    animation: hide_after $timing;
  }
}

@keyframes left_hide {
  0% { transform: translateX(0%); }
  100% { transform: translateX(50%); }
}

.left_hide {
  &.topnav {
    z-index: 600;
  }
  &.progress-top {
    z-index: 600;
  }
  z-index: 400;
  animation: left_hide $timing cubic-bezier(0.465, 0.183, 0.153, 0.946);
  &:after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    background: $noir;
    opacity: 0;
    animation: hide_after $timing;
  }
}

@keyframes left_show {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(0%); }
}

.left_show {
  &.topnav {
    z-index: 900;
  }
  &.progress-top {
    z-index: 900;
  }
  z-index: 800;
  animation: left_show $timing cubic-bezier(0.465, 0.183, 0.153, 0.946);
}


.hidden {
  display: none;
}

.success {
  animation: borderSuccess .5s ease-in 1;
}

@keyframes borderSuccess {
  0% {
    border-color: rgba(2, 255, 99, 1);
  }
  100% {
    border-color: $pilier_color;
  }
}

@keyframes borderFail {
  0% {
    border-color: rgba(255, 0, 0, 1);
  }
  100% {
    border-color: $pilier_color;
  }
}


.anim {
  animation: animation 1000ms cubic-bezier(0.465, 0.183, 0.153, 0.946);
  &.draggable-mirror,
  &.draggable--original,
  &.draggable-source--is-dragging { animation: none; }
  &.icon-question { animation : animcons 700ms cubic-bezier(0.465, 0.183, 0.153, 0.946); }
  &.mot {
    animation: animation-mot 1000ms cubic-bezier(0.465, 0.183, 0.153, 0.946);
  }
  &.formtext {
    animation: animation-formtext 1000ms cubic-bezier(0.465, 0.183, 0.153, 0.946);
  }
}

@keyframes animation {
  0% {transform: scale(1);}
  50% {transform: scale(1.35);}
  100% {transform: scale(1);}
}

@keyframes animation-mot {
  0% {transform: scale(1);}
  50% {transform: scale(1.15);}
  100% {transform: scale(1);}
}

@keyframes animation-formtext {
  0% {transform: scale(1);}
  50% {transform: scale(1.1);}
  100% {transform: scale(1);}
}

@keyframes animcons {
  0% {transform: scale(1);}
  25% {transform: scale(1.35);}
  50% {transform: scale(1);}
  75% {transform: scale(1.35);}
  50% {transform: scale(1);}
}
