//Style pour pillarmenu.hmtl
//Slider annonçant le pilier sélectionné

.loader-pilier {
  z-index: 500;
  width: 100%;
  height: 100%;
  position: fixed;
  &::before {
    @include icomoon;
    color: white;
    @include absoluteCenter;
    font-size: em(220,16);
  }
  @each $pilier, $color in $piliers {
    &.#{$pilier} {
      background-color: map-get($piliers,$pilier);
      &::before {
        content: getPicto($picto_piliers, $pilier);
      }
    }
  }
}

//Corps de la page
li {
  list-style: none;
}
.topnav {
  position: relative;
  z-index: 900;
}
.pillarnav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $pilier_color;
}
.modulelist {
  white-space: nowrap;
  display: flex;
  width: auto;
  flex-wrap: nowrap;
  align-items: flex-end;
  overflow-x: auto;
  height: 100%;
}

.module {
  &:first-child {
    margin-left: 5%;
  }
  position: relative;
  margin-bottom: 8%;
  margin-right: 5%;
  width: 35%;
  height: 40%;
  background-size: 30%;
  border-radius: 3.6vw;
  flex: 0 0 auto;
  padding: auto;
  p {
    @include absoluteCenter;
    font-size: em(30,16);
    color: $pilier_color;
    font-weight: bold;
  }
  &:last-child::after {
    content: "";
    width: 12.5%;
    height: 1px;
    position: absolute;
    left: 100%;
    top: 0;
  }

  &.locked {
    background: $pilier_darken;
    &::before {
      @include icomoon;
      content:"\e902";
      color: $pilier_color;
      @include absoluteCenter;
      font-size: em(50,16);
    }
    progress,
    p {
      display: none;
    }
  }
}

.progress-container {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 3.6vw;
  overflow: hidden;
  width: 100%;
  height: 100%;
  progress {
    appearance: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: white;
    &::-webkit-progress-bar {
      background: white;
    }
    &::-webkit-progress-value {
      background: $progress;
    }
    &::-moz-progress-bar {
      background: $progress;
    }
  }
}

.topnav_back {
  font-size: em(45,16);
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  margin: 0.1em;
  padding: 0.15em 0.35em;
}

.topnav_home {
  font-size: em(45,16);
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  margin: 0.1em;
  padding: 0.15em 0.35em;
}
