//Style pour 19wordselectword.hmtl
.consigneonly.type21 {
  .exotext {
    min-height: 1.5em;
    text-transform: none;
    font-size: em(48,90);
    min-width: em(250,48);
    &.exotext-peigne {
      u {
        position: relative;
        min-width: 1em;
        display: inline-block;
        line-height: 1.1em;
        &:first-child::after {
          border-left: 5px solid $pilier_darken;
        }
        &::after {
          content: '';
          border: 5px solid $pilier_darken;
          border-left: none;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          top: 60%;
          border-top: none;
        }
      }
    }
    &.exotext-cases {
      i {
        position: relative;
        min-width: 1em;
        display: inline-block;
        line-height: 1.1em;
        &:first-child::after {
          border-left: 5px solid $pilier_darken;
        }
        &::after {
          content: '';
          border: 5px solid $pilier_darken;
          border-left: none;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          top: 0;
        }
      }
    }
  }
}
.consigneonly.type21, .modal-input {
  input {
    text-transform: none;
  }
}
