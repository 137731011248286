//Style pour 22hearingselectword
.consigne.type22.type19 {
  &>* {
    width: auto;
  }
  p {
    padding-right: .3em;
  }
  
}
