//Style pour 12hearingtypeexo.hmtl

.consigneonly .hearing {
  font-size: .5156em;
}

.consigneonly.type12a {
  .exotext {
    min-width: em(250,90);
  } 
}
