//Style pour 13hearingselectimg.hmtl

.consigneonly {
  &.type13 {
    justify-content: space-between;
    margin-left: em(48,90);
    margin-right: em(85,90);
    width: auto;
    .hearing, p {
      margin-right: em(30,90);
    }
  }
  .hearing {
  font-size: .5156em;
  }
  .image-hearing {
    width: 30%;
    .image-container {
      background-size: cover;
      &::before {
        padding-top: 100%;
        content: '';
        display: block;
      }
      margin-bottom: em(30,90);
      position: relative;
      border: em(6,90) solid $pilier_color;
      border-radius: 17%;
      box-sizing: border-box;
      overflow: hidden;
      img {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    .hearing {
      font-size: em(25,90);
    }
  }
}
