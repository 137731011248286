/* ==========================================================================
   >>RESET GENERAL
   ========================================================================== */
/* --------------------------------------------------------------------------
   ->Hard reset
   -------------------------------------------------------------------------- */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dialog, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed,  figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 1em;
	font: inherit;
	vertical-align: baseline;
	text-decoration: none;
	outline: none;
	outline-style: none;
	-webkit-touch-callout: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

/* --------------------------------------------------------------------------
   ->HTML5
   -------------------------------------------------------------------------- */
article, aside, datagrid, datalist, details, dialog, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
	display: block;
}
audio, canvas, progress, video {
	display: inline-block;
	vertical-align: baseline;
}
abbr, eventsource, mark, meter, time, output, bb {
	display: inline;
}

/* --------------------------------------------------------------------------
   ->Images, iframes
   -------------------------------------------------------------------------- */
img,
iframe {
	border: 0;
	vertical-align: middle;
}
img {
  -ms-interpolation-mode: bicubic;
	width: auto;
}
img {
  height: auto;
}
// img:not([src$=".svg"]) {
//   height: auto;
// }

a {
	text-decoration: none;
}
a img {
	border: 0;
}
svg:not(:root) {
	overflow: hidden;
}

/* --------------------------------------------------------------------------
   ->Autres éléments
   -------------------------------------------------------------------------- */
/* Tables */
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* Audio */
audio:not([controls]) {
	display: none;
	height: 0;
}

/* Links (Remove the gray background color from active links in IE 10) */
a {
	background-color: transparent;
}

/* --------------------------------------------------------------------------
   ->Modèle de boite
   -------------------------------------------------------------------------- */
html {
	box-sizing: border-box;
}
*,
*:before,
*:after {
	box-sizing: inherit;
}
