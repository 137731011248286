.homescreen {
  position: absolute;
  background: white;
  top: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  width: 100%;

}
.modal-input.home-modal {
  input {
    color: $pilier_color;
  }
}
.form-container {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
}
#formulaire {
  display: flex;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  font-size: em(30,16);
  height: em(110,30);
  .forms {
    color: $colormain;
    font-weight: bold;
    width: 75%;
    .formtext {
      &:first-child {
        margin-bottom: em(16.5,30);
      }
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    p {
      display: inline-block;
      width: 30%;
    }
    .field {
      line-height: em(45.5,30);
      display: inline-block;
      width: 60%;
      background-color: $grisclair;
      border: none;
      border-radius: em(20,30);
      height: em(45.5,30);
      padding: 0 .25em;
      text-transform: uppercase;
      &.invalidinput {
        border: em(5,30) solid $errorcolor;
      }
    }
  }
  button {
    font-size: em(50,30);
    width: em(107.5,50);
    height: em(107.5,50);
    color: white;
    background-color: $colormain;
    border-radius: 25px;
  }
}

.icon-user {
  position: absolute;
  top: 25.6%;
  left: 50%;
  transform: translate(-50%,-50%);
  color: $colormain;
  font-size: em(72,16);
  text-align: center;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}



.field.validinput {
  border: none;
  font-size: 1em;
  margin-left: 0.2em;
  width: 50%;
  border-radius: em(20,30);
  color: $colormain;
  transition-duration: 0.3s;
}

.field.invalidinput {
  border: em(4,30) solid $errorcolor;
  font-size: 1em;
  margin-left: 0.2em;
  width: 50%;
  border-radius: 25px;
  color: $colormain;
  transition-property: border;
  transition-duration: 0.3s;
}

#formerror {
  color: $errorcolor;
}
