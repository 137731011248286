/* ==========================================================================
   >>RESET FORMS
   ========================================================================== */
/* --------------------------------------------------------------------------
  ->Reset et normalisation
  -------------------------------------------------------------------------- */
/* Général Reset */
button,
input,
textarea,
select,
optgroup {
  margin: 0;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  background-color: transparent;
  outline-style: none; /* Supr bordure bleu chrome et safari */
  text-transform: none;
  letter-spacing: inherit;
  border: none;
}

/* Remove border radius safari */
input,
textarea,
select {
  -webkit-border-radius: 0px;
}

/* Boutons */
button {
  padding: 0;
  overflow: visible; /* IE 8/9/10/11 overflow hidden */
}

button,
html input[type="button"], /* Fix webkit bug where native `audio` and `video` controls are destroyed. */
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: none;
}

/* Remove inner padding and border in Firefox 4+ */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/* Firefox 4+ setting `line-height` on `input` using `!important` in the UA stylesheet */
input {
  line-height: normal;
}

/* Normalize checkbox and radio button */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */
input[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  // box-sizing: content-box; /* 2 */
  box-sizing: border-box;
}

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/* legend normalize */
legend {
  white-space: normal;
}

/* select normalize */
/* if select styling bugs on WebKit */
/* select { -webkit-appearance: none; } */
select {
  -webkit-appearance: menulist-button;
}

/* --------------------------------------------------------------------------
   ->Styles Généreaux
   -------------------------------------------------------------------------- */
/* Curseur */
label,
.label,
button,
input[type="submit"],
input[type="button"],
input[type="reset"] {
  cursor: pointer;
}

/* Alignement */
label,
.label,
button,
input,
select {
  vertical-align: middle;
}

/* Désactivé */
button[disabled],
input[disabled] {
  cursor: not-allowed;
}

/* Textarea */
textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/* optgroup */
optgroup {
  font-weight: 700;
}
