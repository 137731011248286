.loading-screen {
  background: white;
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  img {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
  }
  p {
    display: none;
    position: absolute;
    top: 73%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: em(24,16);
    color: #29AFA0;
    width: 100%;
    text-align: center;
  }
}

#mySVG {
  width: 100%;
  height: 100%;
}

#logoSVG {
  width: 100%;
  height: 100%;
  path {
    mix-blend-mode: multiply;
  }
}

.version {
  position: absolute;
  bottom: 5%;
  text-align: center;
  left: 0;
  right: 0;
  color: #ccc;
}
