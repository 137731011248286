$colormain : #29AFA0;
$errorcolor: red;
$noir:       #000000;
$gris:       #5D5D5D;
$grismoyen:  #666666;
$gristexte:  #CCCCCC;
$grisfin:    #3C3C3C;
$grisclair:  #EDEDED;
$lockedicon: #474747;
$progress:   #02FF63;
$piliers: (
  "pilier1": #009688,
  "pilier2": #9C27B0,
  "pilier3": #02A9F4,
  "pilier4": #FFC107,
);

$pilier_color: var(--pilier_clr1,#009688);
$pilier_darken: var(--pilier_clr2,#004D3F);


.pilier1 {
  &:root {
    --pilier_clr1:#009688;
    --pilier_clr2:#004D3F;
  }
}

.pilier2 {
  &:root {
    --pilier_clr1:#9C27B0;
    --pilier_clr2:#4A138B;
  }
}

.pilier3 {
  &:root {
    --pilier_clr1:#02A9F4;
    --pilier_clr2:#0D47A1;
  }
}

.pilier4 {
  &:root {
    --pilier_clr1:#FFC107;
    --pilier_clr2:#FF6F00;
  }
}
