@font-face {
  font-family: 'icomoon';
  src: url('../../fonts/icomoon.ttf') format('truetype'),
    url('../../fonts/icomoon.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-home:before {
  content: '\e907';
}
.icon-left:before {
  content: '\e90f';
}
.icon-right:before {
  content: '\e910';
}
.icon-abc:before {
  content: '\e903';
}
.icon-list:before {
  content: '\e906';
}
.icon-work:before {
  content: '\e905';
}
.icon-book:before {
  content: '\e904';
}
.icon-locked:before {
  content: '\e902';
}
.icon-question:before {
  content: '\e901';
}
.icon-user:before {
  content: '\e900';
}
