//Style pour 19wordselectword.hmtl
.consigne.type19 {
  margin-bottom: 5%;
  margin-top: 3%;
  p {
    width: 100%;
  }
}
.reponses.type19 {
  justify-content: center;
  p {
    margin-right: 4%;
    font-size: em(24,48);
    border-radius: em(20,24);
    min-height: em(50,24);
    line-height: em(50,24);
    padding: 0 1em;
    border: em(4,24) solid transparent;
  }
}
