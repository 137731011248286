//Style pour 13hearingselectimg.hmtl

.consigneonly {
  &.type13 {
    justify-content: space-around;
  }
  &.type14 {
    justify-content: space-around;
    .image-hearing {
      width: 25%;
    }
    &.type14a {
      span {
        padding: 0;
        margin: 0;
        background: none;
        color: $pilier_darken;
        min-width: auto;
      }
    }
  }
}
.motlettre {
  width: 65%;
  font-size: em(48,90);
  p {
    vertical-align: middle;
    white-space: nowrap;
  }
  span {
    display: inline-block;
    min-width: 1.1em;
    min-height: 1.1em;
    padding: 0 .15em;
    margin-left: .1em;
    margin-right: .1em;
    vertical-align: baseline;
    background: $grisclair;
    border: em(4,48) solid transparent;
    border-radius: .17em;
    &.false {
      border: solid $errorcolor em(4,48);
    }
    &.span-syllabe {
      min-width: 2em;
    }
  }
  .clicklettre {
    align-items: center;
    display: flex;
    margin-top: 5%;
    font-size: 1em;
    justify-content: center;
    .lettre {
      text-align: center;
      font-size: em(30,48);
      background: $grisclair;
      border-radius: 2.7vw;
      min-height: em(70,30);
      line-height: em(70,30);
      min-width: em(76,30);
      margin-right: 4%;
    }
  }
}
