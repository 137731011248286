.accueil {
  width: 100vw;
  height: 100vh;
  background: white;
}
.buttonlist {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 30%;
  
}
.homebutton {
  display: block;
  color: white;
  background: #00A79F;
  border-radius: 20px;
  height: 2.5em;
  padding: 0 .5em;
  line-height: 2.5em;
  text-align: center;
  margin-bottom: .5em;
}

.modal-install {
  z-index: 3000;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  p {
    text-align: center;
    background: white;
    width: 80%;
    @include absoluteCenter;
    color: #00A79F;
    font-size: em(32,16);
    border-radius: 1.7vw;
    padding: .5em;
  }
}
