.menu {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
  background: white;
  align-items: center;
  & .pilier {
    position: relative;
    background-color: red;
    width: 17.6vw;
    height: 17.6vw;
    border-radius: em(20,16);
    &::before {
      @include icomoon;
      color: white;
      font-size: 13vw;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
    &.locked {
      &::before {
        color: mix(white, $noir, 28%);
      }
      &::after {
        @include icomoon;
        content: "\e902";
        font-size: 6vw;
        position: absolute;
        color: white;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
  }
}
//Pour ajouter des couleurs de piliers : style/sass/main/_couleurs.scss --> Tableau de couleurs de piliers
@each $pilier, $color in $piliers {
  ##{$pilier} {
    background-color: $color;
    &::before {
      content: getPicto($picto_piliers, $pilier);
    }
    &.locked {
      background-color: mix($color, $noir, 28%);
      }
    }
  }

.help {
  font-size: 7.5vw;
  position: absolute;
  top: 0;
  right: 0;
  color: $gris;
  margin: em(26,45);
}
