//Style pour 05modules.hmtl

//Corps de la page
.menu-modules {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $pilier_color;
}
.titre {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%,-50%);
  color: white;
  font-size: em(72,16);
  font-weight: bold;
  z-index: 1;
  white-space: pre;
}
.modulesnav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.exolist {
  display: flex;
  width: auto;
  flex-wrap: nowrap;
  align-items: flex-end;
  overflow-x: auto;
  height: 100%;
}

.exo {
  background: white;
  position: relative;
  margin-bottom: 8%;
  margin-left: 5%;
  width: 30%;
  height: 40%;
  background-size: 30%;
  border-radius: 3.6vw;
  flex: 0 0 auto;
  padding: auto;
  &.fini {
    background: $progress;
  }
  p {
    @include absoluteCenter;
    font-size: em(70,16);
    letter-spacing: 0;
    color: $pilier_color;
    font-weight: bold;
  }
  &:last-child::after {
    content: "";
    width: percentage(1/6);
    height: 1px;
    position: absolute;
    left: 100%;
    top: 0;
  }

  &.locked {
    background: $pilier_darken;
    &::before {
      @include icomoon;
      content:"\e902";
      color: $pilier_color;
      @include absoluteCenter;
      font-size: em(50,16);
    }
    progress,
    p {
      display: none;
    }
  }
}

.progress-container {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 3.6vw;
  overflow: hidden;
  width: 100%;
  height: 100%;
  progress {
    appearance: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: white;
    &::-webkit-progress-bar {
      background: white;
    }
    &::-webkit-progress-value {
      background: $progress;
    }
    &::-moz-progress-bar {
      background: $progress;
    }
  }
}
