//Style pour 11lettertypeexo.hmtl

//Corps de la page

.consigneonly.type11a {
  justify-content: space-around;
  .exotext {
    width: 30%;
    margin: 0 5%;
  }
}
