.mainexo.type25 {
  padding: 0;
  .exosimple {
    margin: 3.5%;
    width: 93%;
    height: calc(93vh - 7%);
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    padding: 8% 10%;
    border-radius: em(5,16);
    
    div {
      display: flex;
      flex-wrap: wrap;
      &>* {
        width: 100%;
      }
      width: 50%;
      height: em(144,16);
      .icon {
        line-height: 1em;
        font-size: em(72,16);
        &::before {
          @include icomoon;
        }
      }
      .text {
        line-height: 1em;
        font-size: em(35,16);
        font-weight: bold;
        color: $grisfin;
      }
      &.fini_success {
        .icon {
          color: $progress;
          &::before {
            content: "\e909";
          }
        }
        .text {
          &::before {
            content: "Bravo !";
          }
        }
      }
      &.fini_failure {
        .icon {
          color: $errorcolor;
          &::before {
            content: "\e90a";
          }
        }
        .text {
          &::before {
            content: "Réessaie !";
          }
        }
      }
    }
  }
}
.fini_next {
  padding: .8em;
  color: white;
  background: $pilier_color;
  border-radius: em(20,16);
  &::before {
    font-size: em(48,16);
    @include icomoon;
    content: "\e907";
  }
}
.fini_success {
  +.fini_next {
    &::before {
      content: "\e910";
    }
  }
}
.fini_failure {
  +.fini_next {
    &::before {
      content: "\e90b";
    }
  }
}
.fini_home {
  padding: .8em;
  color: white;
  background: $grismoyen;
  border-radius: em(20,16);
  &::before {
    font-size: em(48,16);
    @include icomoon;
    content: "\e907";
  }
}
