//Style pour 13hearingselectimg.hmtl
.consigne.type17 {
  font-size: em(72,16);
  margin-bottom: 5%;
  p {
    position: relative;
    width: auto;
    color: $gristexte;
    &:after {
      content: attr(data-text);
      position: absolute;
      left: 0;
      top: 0;
      overflow: hidden;
      width: 100%;
    }
  }
}
.reponses.type17 {
  position: relative;
  text-align: center;
  .hearing {
    font-size: em(30,48);
  }
  .next {
    position: absolute;
    top: 50%;
    left: 85%;
    transform: translate(-50%,-50%);
    &::before {
      font-size: em(66,48);
      @include icomoon;
      content: "\e910";
      color: white;
      background: $pilier_color;
      border-radius: 50%;
      padding-left: .05em 0 .05em .05em;
    }
    width: 25%
  }
}

.karaoke:after {
  color: $pilier_color;
  // duration | timing-function | delay | iteration-count | direction | fill-mode | play-state | name
  animation: .5s linear 1 both run-text;
}

@keyframes run-text {
  from { width: 0 }
  to { width: 100% }
}
