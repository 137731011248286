//Style pour 11lettertypeexo.hmtl

//Corps de la page

.modal-input {
  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  input {
    font-weight: bold;
    font-size: em(48,16);
    width: 80%;
    height: em(85,48);
    padding: 0 em(20,48);
    @include absoluteCenter;
    color: $pilier_color;
    background: white;
    border-radius: 1.7vw;
  }
  &.hidden {
    display: none;
  }
}

.consigneonly {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  font-weight: bold;
  font-size: em(90,16);
  color: $pilier_color;
  align-items: center;
  justify-content: center;
  margin-bottom: 10%;
  text-align: center;
}
.exotext {
  margin-left: 5%;
  background: $grisclair;
  border: em(6,90) solid transparent;
  border-radius: 1.7vw;
  min-height: em(103,90);
  min-width: em(180,90);
  text-align: center;
  padding: .2em .2em;
  &.false {
    border: em(6,103) solid $errorcolor;
    box-sizing: border-box;
    line-height: 1em;
  }
}
