@import 'imports/fonts';
@import 'imports/resets';
@import 'imports/couleurs';
@import 'imports/picto';
@import 'imports/fonctions';
@import 'imports/mixins';

@import '01loading';
@import '02home';
@import '03menu';
@import '05piliers';
@import '06modules';
@import '08basexo';
@import '10dragdropexo';
@import '11lettertypeexo';
@import '11aletterhearingtypeexo';
@import '12hearingtypeexo';
@import '13hearingselectimg';
@import '14hearingselectletter';
@import '14hearingselectletter';
@import '16hearingdragdropletters';
@import '16ahearingdragdropletterswithtext';
@import '17hearingreadsyllab';
@import '19wordselectword';
@import '21hearingtypenameexo';
@import '22hearingselectword';
@import '23hearingfillform';
@import '24fillform';
@import '25finexo';

@import 'homeinstall';

// Message lors de l’utilisation en mode portrait
@import 'orientation';

@import 'animation';

html,
body {
  font-size: 2.39vw;
  font-family: Arial;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

html {
  position: relative;
}

body {
  position: fixed;
}

.exolist::-webkit-scrollbar {
  display: none;
}
@media screen and (min-aspect-ratio: 17/9) {
  body {
    font-size: 1.8vw;
  }
}

main {
  width: 100%;
  height: 100%;
}
