//Style pour 19wordselectword.hmtl
.consigneonly.type23 {
  justify-content: space-around;
  align-items: center;
  padding: 0 8% 0 6%;
  .hearing {
    width: 20%;
  }
  .forms {
    width: 100%;
    font-size: em(30,90);
    padding-left: 2%;
    .formtext {
      border: em(6,30) solid transparent;
      border-radius: em(20,30);
      margin-bottom: 4%;
      height: 33%;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    p {
      display: inline-block;
      width: 50%;
    }
    .field {
      &.field-cadre {
        background: $grisclair;
        text-transform: uppercase;
      }
      &.field-peigne {
        overflow: hidden;
        u {
          position: relative;
          min-width: 1em;
          display: inline-block;
          line-height: 1.1em;
          &:first-child::after {
            border-left: em(5,48) solid $pilier_darken;
          }
          &::after {
            content: '';
            border: em(5,48) solid $pilier_darken;
            border-left: none;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            top: 60%;
            border-top: none;
          }
        }
      }
      &.field-cases {
        overflow: hidden;
        i {
          position: relative;
          min-width: 1em;
          display: inline-block;
          line-height: 1.1em;
          &:first-child {
            min-width: calc(1em + 5px);
            &::after {
              border-left: em(5,48) solid $pilier_darken;
            }
          }
          &::after {
            content: '';
            border: em(5,48) solid $pilier_darken;
            border-left: none;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
          }
        }
      }
      border: em(6,30) solid transparent;
      display: inline-block;
      width: 50%;
      border-radius: em(20,30);
      height: em(45,30);
      padding: 0 .25em;
      &.false {
        border: em(5,30) solid $errorcolor;
      }
    }
  }
}
