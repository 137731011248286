@media (orientation: landscape) {
  .orientation {
    display: none;
    z-index: 0;
  }
}

@media (orientation: portrait) {
  .orientation {
    display: block;
    z-index: 3000;
  }
}

.orientation {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: white;
  .icon-turn {
    @include icomoon;
    font-size: em(144,16);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: #29AFA0;
    &::before {
      content: '\e90c';
    }
  }
  .orientation-text {
    color: #29AFA0;
    font-size: em(48,16);
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    text-align: center;
  }
}
